<template>
  <v-card class="rounded-lg" color="accent">
    <section class="pa-4">
      <v-card-title class="px-0 pt-0">
        Solicitação de Saque
        <v-spacer />
        <v-btn class="rounded-lg" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- navigation -->
      <v-slide-group
        v-model="tab"
        class="mb-4 foreground rounded-xl"
        mandatory
        show-arrows
      >
        <v-row no-gutters>
          <v-col v-for="(item, i) in tabs" :key="i">
            <v-slide-item v-slot="{ active, toggle }">
              <v-btn
                active-class="primary"
                :input-value="active"
                :plain="!active"
                depressed
                rounded
                small
                block
                @click="toggle"
              >
                {{ item }}
              </v-btn>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>

      <v-tabs-items v-model="tab" class="transparent mb-6">
        <!-- info -->
        <v-tab-item :value="0">
          <!-- resend transaction -->
          <v-list-item class="foreground rounded-lg mb-2">
            <v-list-item-content>
              <v-list-item-title>Reenviar transação</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="primary"
                depressed
                dark
                small
                @click="resendDialog = true"
              >
                Reenviar
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- qrcode -->
          <v-expansion-panels
            v-if="selected.withdraw.pixCode"
            v-model="panel"
            class="mb-2"
            flat
          >
            <v-expansion-panel class="foreground">
              <v-expansion-panel-header>
                <v-list-item-title>PIX: QR code</v-list-item-title>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="d-flex flex-column align-center justify-center">
                  <vue-qr
                    :text="selected.withdraw.pixCode"
                    :size="250"
                    class="rounded-lg elevation-3 mb-4"
                  />

                  <!-- pix code -->
                  <v-row class="mb-4" no-gutters>
                    <input
                      v-model="selected.withdraw.pixCode"
                      id="code"
                      class="text-right primary_text--text field mr-4"
                      readonly
                      @focus="copy('code')"
                    />

                    <v-icon color="secondary" small @click="copy('code')">
                      mdi-content-copy
                    </v-icon>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- user info -->
          <v-list class="foreground pa-0 rounded-lg mb-2">
            <v-list-item>
              <v-list-item-action-text> Nome </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.userName"
                  id="userName"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('userName')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('userName')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text> Documento </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.document"
                  id="document"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('document')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('document')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text>
                Cartão aplicado
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right text-body-2 mr-1">
                  <v-icon color="green accent-4" v-if="cardTransaction"
                    >mdi-credit-card-check</v-icon
                  >
                  <v-icon color="grey darken-1" v-else
                    >mdi-credit-card-remove</v-icon
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- transaction info -->
          <v-list class="foreground pa-0 rounded-lg mb-2">
            <v-list-item>
              <v-list-item-action-text> ID do Pedido</v-list-item-action-text>

              <v-list-item-content>
                <input
                    v-model="selected.orderId"
                    id="orderId"
                    class="text-right primary_text--text field"
                    readonly
                    @focus="copy('orderId')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('orderId')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <!-- transfer withdraw -->
            <section v-if="selected.bankInfo">
              <v-list-item class="foreground">
                <v-list-item-action-text> Agência</v-list-item-action-text>

                <v-list-item-content>
                  <input
                      v-model="selected.withdraw.agency"
                      id="agency"
                      class="text-right primary_text--text field"
                      readonly
                      @focus="copy('agency')"
                  />
                </v-list-item-content>

                <v-list-item-icon class="px-0">
                  <v-icon color="secondary" small @click="copy('agency')">
                    mdi-content-copy
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-action-text> Conta</v-list-item-action-text>

                <v-list-item-content>
                  <input
                      v-model="selected.withdraw.accountNumber"
                      id="accountNumber"
                      class="text-right primary_text--text field"
                      readonly
                      @focus="copy('accountNumber')"
                  />
                </v-list-item-content>

                <v-list-item-icon class="px-0">
                  <v-icon
                      color="secondary"
                      small
                      @click="copy('accountNumber')"
                  >
                    mdi-content-copy
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item>
                <v-list-item-action-text> Banco</v-list-item-action-text>

                <v-list-item-content>
                  <v-list-item-title class="text-right text-body-2">
                    {{ selected.bankInfo.name }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-right">
                    {{ selected.bankInfo.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar size="32" class="px-0">
                  <v-avatar size="32" color="foreground lighten-2">
                    <v-img :src="selected.bankInfo.image"/>
                  </v-avatar>
                </v-list-item-avatar>
              </v-list-item>

              <v-list-item>
                <v-list-item-action-text>
                  Tipo de Conta
                </v-list-item-action-text>

                <v-list-item-content>
                  <v-list-item-title class="text-right">
                    {{ selected.withdraw.isCurrent ? "Corrente" : "Poupança" }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </section>

            <!-- pix withdraw -->
            <v-list-item v-else>
              <v-list-item-action-text> Chave Pix</v-list-item-action-text>

              <v-list-item-content>
                <input
                    v-model="selected.withdraw.pixKey"
                    id="pixKey"
                    class="text-right primary_text--text field"
                    readonly
                    @focus="copy('pixKey')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('pixKey')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text>
                Valor de Saque
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  R$ {{ moneyMask(selected.convertedValue) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <!-- receipts -->
        <v-tab-item :value="1">
          <v-list-item class="foreground rounded-lg">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">mdi-receipt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Comprovante do analista</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row no-gutters>
                <v-btn
                    v-if="analystReceipt"
                    class="mr-2 rounded-lg"
                    target="_blank"
                    :href="analystReceipt"
                    small
                    icon
                >
                  <v-icon>mdi-image-search-outline</v-icon>
                </v-btn>

                <SendReceipt
                    :transaction="selected"
                    @success="getUrlReceipt($event)"
                />
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-tab-item>

        <!-- history -->
        <v-tab-item :value="2">
          <v-list class="transparent pa-0" nav>
            <v-list-item
                v-for="(item, i) in transactions"
                :key="i"
                :style="`background-color: ${
                getStatus(item.transactionStatusId).hexa
              }`"
            >
              <v-row class="align-center" no-gutters>
                <v-col cols="6" sm="3" class="my-2 text-center">
                  <v-list-item-title class="text-caption">
                    {{ getMethod(item.paymentMethod) }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-caption">
                    ID {{ item.orderId }}
                  </v-list-item-subtitle>
                </v-col>

                <v-col cols="6" sm="3" class="my-2 text-center">
                  <v-list-item-title class="text-caption">
                    R$ {{ moneyMask(item.convertedValue) }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-caption">
                    Valor
                  </v-list-item-subtitle>
                </v-col>

                <v-col cols="6" sm="3" class="my-2 text-center">
                  <v-list-item-title class="text-caption">
                    {{ formatDate(item.createdAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    Criação
                  </v-list-item-subtitle>
                </v-col>

                <v-col cols="6" sm="3" class="my-2 text-center">
                  <v-list-item-title class="text-caption">
                    {{ formatDate(item.updatedAt) }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    Atualização
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-list-item>

            <v-btn
                :disabled="page >= pageCount"
                block
                text
                @click="
                page++;
                getHistory();
              "
            >
              Carregar mais
            </v-btn>
          </v-list>
        </v-tab-item>
      </v-tabs-items>

      <v-row v-if="selected.transactionStatusId === 1">
        <v-col cols="6">
          <v-btn color="red" large block dark @click="rejectDialog = true">
            Rejeitar
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn color="green" large block dark @click="approveDialog = true">
            Aprovar
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else no-gutters>
        <v-card
            :color="getStatus(selected.transactionStatusId).color"
            height="44"
            width="100%"
            flat
        >
          <v-row
              class="justify-center align-center fill-height text-h6"
              no-gutters
          >
            {{ getStatus(selected.transactionStatusId).label }}
          </v-row>
        </v-card>
      </v-row>
    </section>

    <!-- dialog approve -->
    <v-dialog v-model="approveDialog" max-width="400">
      <v-card v-if="approveDialog" color="accent">
        <v-card-title>Aprovar saque</v-card-title>

        <v-card-text class="text-body-1">
          Deseja mesmo
          <span class="green--text font-weight-bold">aprovar</span> esta
          transação?
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn plain @click="approveDialog = false"> Cancelar</v-btn>

          <v-btn color="primary" @click="submit(2)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog reject -->
    <v-dialog v-model="rejectDialog" max-width="400">
      <v-card v-if="rejectDialog" color="accent">
        <v-card-title>Rejeitar saque</v-card-title>

        <v-card-text class="text-body-1">
          Deseja mesmo
          <span class="red--text font-weight-bold">rejeitar</span> esta
          transação?
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn plain @click="rejectDialog = false"> Cancelar</v-btn>

          <v-btn color="primary" @click="submit(3)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog resend -->
    <v-dialog v-model="resendDialog" max-width="400">
      <v-card v-if="resendDialog" color="accent">
        <v-card-title>Reenviar transação</v-card-title>

        <v-card-text class="text-body-1">
          Deseja mesmo
          <span class="primary--text font-weight-bold">reenviar</span> esta
          transação?
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn plain @click="resendDialog = false"> Cancelar</v-btn>

          <v-btn color="primary" @click="resend()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader-hover v-if="loading"/>
  </v-card>
</template>

<script>
import {
  transactionStatus,
  transactionSome,
  resendTransaction,
  getTransactions,
} from "@/services/transaction";
import {displayAlert, moneyMask, formatDate} from "@/utils";
import SendReceipt from "./SendReceipt";
import VueQr from "vue-qr";

export default {
  data() {
    return {
      loading: false,
      panel: 0,
      approveDialog: false,
      rejectDialog: false,
      resendDialog: false,
      tabs: ["Informações", "Comprovantes", "Histórico"],
      tab: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      transactions: [],
      cardTransaction: false,
      analystReceipt: null,
    };
  },

  props: {
    selected: {
      type: Object,
      default: {},
    },
  },

  components: {
    SendReceipt,
    VueQr,
  },

  beforeMount() {
    this.getTransactionSome();
    this.getHistory();

    this.analystReceipt = this.selected.urlReceiptAdmin;
  },

  methods: {
    async submit(value) {
      try {
        this.loading = true;

        const payload = {
          transactionId: this.selected.id,
          status: value,
        };

        await transactionStatus(payload).then(() => {
          this.displayAlert(`Saque avaliado com sucesso`);
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // resend transaction
    async resend() {
      try {
        this.loading = true;

        const payload = {
          transactionId: this.selected.id,
        };

        await resendTransaction(payload).then(() => {
          this.displayAlert("Transação reenviada com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getTransactionSome() {
      try {
        const payload = this.selected.document;

        await transactionSome(payload).then((res) => {
          this.cardTransaction = res.body.has;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getHistory() {
      try {
        const payload = {
          page: String(this.page - 1),
          size: this.itemsPerPage,
          sort: "createdAt,desc",
          paymentMethod: "TRANSFER,CARD,BOLETO,PIX,PICPAY,WITHDRAW",
        };

        if (this.selected.param1 && Number(this.selected.param1))
          payload.param1 = this.selected.param1;
        else
          payload.document = this.selected.document;

        await getTransactions(payload).then((res) => {
          this.transactions = this.transactions.concat(res.body.content.filter(e => e.param1 == this.selected.param1 || e.document === this.selected.document));
          this.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      }
    },

    getUrlReceipt(data) {
      this.analystReceipt = data;
    },

    // return label of method
    getMethod(value) {
      switch (value) {
        case "TRANSFER":
          return "Transf.";
        case "CARD":
          return "Cartão";
        case "BOLETO":
          return "Boleto";
        case "PIX":
          return "Pix";
        case "PICPAY":
          return "PicPay";
        case "WITHDRAW":
          return "Saque";
        default:
          return "";
      }
    },

    // return label of status
    getStatus(value) {
      switch (value) {
        case 1:
          return {
            label: "Aguardando",
            color: `amber`,
            hexa: "#FFC10740",
          };
        case 2:
          return {
            label: "Saque aprovado",
            color: "green",
            hexa: "#4CAF5040",
          };
        case 3:
          return {
            label: "Saque recusado",
            color: "red",
            hexa: "#F4433640",
          };
        case 4:
          return {
            label: "Saque cancelado",
            color: "grey",
            hexa: "#9E9E9E40",
          };
        case 5:
          return {
            label: "Saque estornado",
            color: "grey darken-1",
            hexa: "#9C27B040",
          };
        default:
          return {
            label: "Carregando",
            color: "primary_text--text",
            hexa: "",
          };
      }
    },

    // copy do clipboard
    copy(value) {
      var textBox = document.getElementById(value);
      textBox.select();
      document.execCommand("copy");
    },

    close() {
      this.$emit("close");
    },

    moneyMask,

    formatDate,

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>
