<template>
  <v-container class="py-4" fluid>
    <v-row class="mx-2 mb-4 text-h6 font-weight-black align-center" no-gutters>
      <v-icon class="mr-3">mdi-arrow-up-thick</v-icon>
      Saques

      <v-spacer />

      <v-progress-circular
        v-if="loadingInfo"
        color="primary"
        size="34"
        indeterminate
      />

      <div v-else>
        <span class="text-body-2 mr-2 secondary_text--text">
          Saques pendentes:
        </span>

        <v-chip
          class="text-body-2 mr-4 primary_text--text"
          color="accent"
          label
        >
          {{ info.qtd }}
        </v-chip>

        <span class="text-body-2 mr-2 secondary_text--text">
          Valor total:
        </span>

        <v-chip
          class="text-body-2 mr-2 primary_text--text"
          color="accent"
          label
        >
          <v-icon color="red" left>mdi-menu-down</v-icon>
          R$ {{ moneyMask(info.amount) }}
        </v-chip>

        <v-btn
          class="rounded-lg"
          :disabled="loadingInfo"
          small
          icon
          @click="getInfo()"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </div>
    </v-row>

    <Filters
      class="mb-2"
      :dataFilters="require('@/assets/filters/withdraw.json')"
      @apply-filter="applyFilter"
    />

    <section>
      <!-- summary colors  -->
      <v-chip-group v-model="status" column class="mb-2" @change="getData()">
        <v-chip
          v-for="(item, i) in statusFilter"
          color="transparent"
          class="mr-1"
          :active-class="`${item.color}--text`"
          :key="i"
          :value="item.value"
          small
        >
          <v-icon :color="item.color" left> mdi-square-rounded </v-icon>
          <span class="text-caption">
            {{ item.label }}
          </span>
        </v-chip>
      </v-chip-group>

      <!-- table -->
      <v-card class="rounded-lg" outlined>
        <v-data-table
          class="accent pointer-hand"
          :headers="headers"
          :items="transactions"
          :items-per-page="itemsPerPage"
          :mobile-breakpoint="0"
          :item-class="rowClasses"
          :custom-sort="customSort"
          hide-default-footer
          @dblclick:row="handleTransaction"
        >
          <!-- value -->
          <template v-slot:[`item.convertedValue`]="{ item }">
            R$ {{ moneyMask(item.convertedValue) }}
          </template>

          <!-- bank -->
          <template v-slot:[`item.bank`]="{ item }">
            <div v-if="item.withdraw" class="single-line">
              <v-tooltip
                v-if="item.withdraw.bankCode"
                color="grey darken-4"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    v-on="on"
                    color="accent lighten-1"
                    size="32"
                  >
                    <v-img
                      :src="handleBank(item.withdraw.bankCode).image"
                      contain
                    />
                  </v-avatar>
                </template>

                <span>
                  {{ handleBank(item.withdraw.bankCode).name }}
                </span>
              </v-tooltip>

              <v-avatar v-else color="accent lighten-1" size="32">
                <v-img
                  src="https://logospng.org/download/pix/logo-pix-icone-512.png"
                />
              </v-avatar>
            </div>

            <span v-else>~</span>
          </template>

          <!-- created -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- updated -->
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>

          <!-- receipt -->
          <template v-slot:[`item.receipt`]="{ item }">
            <v-tooltip color="grey darken-4" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  :color="analystReceipt(item) ? 'secondary' : 'grey'"
                  small
                  @click="analystReceipt(item, true)"
                >
                  mdi-receipt
                </v-icon>
              </template>

              <span>Comprovante do analista</span>
            </v-tooltip>
          </template>

          <!-- open -->
          <template v-slot:[`item.open`]="{ item }">
            <v-btn
              class="rounded-lg"
              :disabled="!item.withdraw"
              icon
              small
              @click="handleTransaction(null, { item })"
            >
              <v-icon> mdi-open-in-app </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="page"
        class="py-4"
        color="primary"
        :length="pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <ManageWithdraw
        v-if="dialog"
        :selected="selected"
        @success="getData()"
        @close="dialog = false"
      />
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import {
  getTransactions,
  getWithdrawInfo,
  getBanks,
} from "@/services/transaction";
import { displayAlert, moneyMask, formatDate } from "@/utils";
import ManageWithdraw from "@/components/transactions/ManageWithdraw";
import Filters from "@/components/filters/Filters";

export default {
  data() {
    return {
      loading: true,
      loadingInfo: false,
      interval: null,
      dialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      sort: "createdAt,desc",
      headers: [
        { text: "ID", value: "orderId" },
        { text: "Usuário", value: "userName" },
        { text: "Documento", value: "document" },
        { text: "Valor", value: "convertedValue" },
        { text: "Criação", value: "createdAt" },
        { text: "Atualização", value: "updatedAt" },
        { text: "Banco", value: "bank", sortable: false },
        {
          text: "Comprov.",
          value: "receipt",
          align: "center",
          sortable: false,
        },
        { text: "Abrir", value: "open", align: "center", sortable: false },
      ],
      statusFilter: [
        {
          label: "Aguardando",
          color: "amber",
          value: 1,
        },
        {
          label: "Aprovado",
          color: "green",
          value: 2,
        },
        {
          label: "Rejeitado",
          color: "red",
          value: 3,
        },
      ],
      transactions: [],
      filter: [],
      status: "",
      banks: [],
      selected: {},
      info: null,
    };
  },

  components: {
    ManageWithdraw,
    Filters,
  },

  created() {
    this.getBanks();
  },

  beforeMount() {
    this.getData();
    this.getInfo();

    this.interval = setInterval(() => {
      if (!this.loading) this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: String(this.page - 1),
          size: this.itemsPerPage,
          sort: this.sort,
          paymentMethod: "WITHDRAW",
          transactionStatusId: this.status,
          ...this.filter,
        };

        await getTransactions(payload).then((res) => {
          this.transactions = res.body.content;
          this.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async getInfo() {
      try {
        this.loadingInfo = true;

        await getWithdrawInfo().then((res) => {
          this.info = res.body;
        });
      } catch (err) {
        console.log("err", err);
      } finally {
        setTimeout(() => {
          this.loadingInfo = false;
        }, 500);
      }
    },

    // get bank list
    async getBanks() {
      try {
        await getBanks().then((res) => {
          this.banks = res.body;
        });
      } catch (err) {
        console.log(err);
      }
    },

    // open transaction
    handleTransaction(event, { item }) {
      if (!item.withdraw) return;

      this.selected = {
        ...item,
        bankInfo: item.withdraw.bankCode
          ? this.handleBank(item.withdraw.bankCode)
          : null,
      };

      this.dialog = true;
    },

    // return bank
    handleBank(value) {
      const bank = this.banks.find((e) => e.id === value);

      return {
        code: value,
        name: bank.name || "",
        image: bank.urlIcon || "",
      };
    },

    // return label of status
    getStatus(value) {
      switch (value) {
        case 1:
          return {
            label: "Aguardando",
            color: "amber",
            icon: "mdi-timer-sand",
          };
        case 2:
          return {
            label: "Aprovado",
            color: "green",
            icon: "mdi-checkbox-marked-circle-outline",
          };
        case 3:
          return {
            label: "Recusado",
            color: "red",
            icon: "mdi-close",
          };
        case 4:
          return {
            label: "Cancelado",
            color: "grey",
            icon: "mdi-cancel",
          };
        case 5:
          return {
            label: "Estornado",
            color: "purple",
            icon: "mdi-undo",
          };
        default:
          return {
            label: "",
            color: "",
            icon: "",
          };
      }
    },

    // return row status color
    rowClasses(item) {
      return `${this.getStatus(item.transactionStatusId).color}--text text--${
        this.$vuetify.theme.dark ? "lighten-1" : "darken-2"
      }`;
    },

    // get data with filter
    applyFilter(value) {
      this.loading = true;
      this.filter = value;
      this.page = 1;

      this.getData();
    },

    // sort table
    customSort(items, sortBy, sortDesc) {
      let value = sortBy[0];
      let sort = "";

      if (value) sort = value + "," + (sortDesc[0] ? "desc" : "asc");
      else sort = "createdAt,desc";

      if (sort !== this.sort) {
        this.sort = sort;
        this.getData();
      }

      return items;
    },

    // receipt analyst
    analystReceipt(data, open = false) {
      if (data.urlReceiptAdmin) {
        if (open) window.open(data.urlReceiptAdmin, "_blank").focus();
        return true;
      }
      return false;
    },
    displayAlert,

    moneyMask,

    formatDate,
  },
};
</script>

<style lang="scss" scoped>
</style>